<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();
	let className: string = '';

	export { className as class };
</script>

<button
	class={'glow-btn relative min-h-[60px] min-w-[260px] rounded-lg ' + className}
	on:click={() => dispatch('click')}
	aria-label="Book Transfer Now"
>
	<div
		class="btn-glow absolute z-0 h-full min-h-[60px] w-full min-w-[260px] overflow-hidden rounded-lg blur-[25px]"
	/>
	<span
		class="btn relative z-0 flex h-full min-h-[60px] w-full min-w-[260px] items-center justify-center overflow-hidden rounded-lg text-white no-underline"
	>
		<slot />
	</span>
</button>

<style type="postcss">
	.btn::before,
	.btn-glow::before {
		content: '';
		@apply absolute left-1/2 top-1/2 h-[9999px] w-[9999px];
		background-image: conic-gradient(
			rgba(0, 0, 0, 0),
			rgba(141, 121, 238, var(--un-bg-opacity)),
			rgba(0, 0, 0, 0) 25%
		);
		background-repeat: no-repeat;
		background-position: 0 0;
		transform: translate(-50%, -50%) rotate(0deg);
		@apply -z-20;
		animation: glow 5s linear infinite;
	}

	.btn:after {
		content: '';
		@apply -z-1 bg-linear-purple-100 absolute left-[2px] right-[2px] h-[calc(100%-5px)] w-[calc(100%-5px)] rounded-lg;
	}

	@keyframes glow {
		100% {
			transform: translate(-50%, -50%) rotate(1turn);
		}
	}
</style>
